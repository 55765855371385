/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/2
 * @description
 *   reset.api.ts of WeTrade
 */
import requestResult from '@/common/request/request'

export const requestReset = requestResult<{a: number}>('/api/user/update/password')

export const requestCode = (countryCode: string, mobile: string, type: number) => requestResult('/api/sms/send')({
  type,
  countryCode,
  mobile,
})
