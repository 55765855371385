/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/2
 * @description
 *   reset.ts of WeTrade
 */
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import { requestCode, requestReset } from '@/pages/reset/reset.api'
import { useRouter } from 'vue-router'
import { showAlert } from '@/components/popup/popup'
import { bindMobile, User } from '@/modules/user/user.api'
import { login } from '@/modules/user/user'

const useReset = () => {
  const router = useRouter()
  const [commit, progress] = useRequest(requestReset)
  const post = (data: Data) => {
    return commit(data).then(() => {
      showAlert(translate('toast_11', 'Password Set Successfully, Please Login!'))
      router.replace('/login')
    })
  }

  return [post, progress]
}

export const useBindMobile = () => {
  const router = useRouter()
  const [commit, progress] = useRequest(bindMobile)
  const post = (data: Data) => {
    return commit(data).then((resp) => {
      login(resp as User)
      if (router) router.replace({ name: 'home' })
    })
  }

  return [post, progress]
}

export const useResetCode = () => useRequest(requestCode)

export default useReset
