
import Button from '@/components/Button.vue'
import TheMobileInput from '@/pages/login/components/TheMobileInput.vue'
import ThePasswordInput from '@/pages/login/components/ThePasswordInput.vue'
import TheCodeInput from '@/pages/register/components/TheCodeInput.vue'
import { registerFormSchema } from '@/pages/register/register'
import useReset, { useBindMobile, useResetCode } from '@/pages/reset/reset'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Form from 'common/form/Form.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Reset',
  components: { Button, ThePasswordInput, TheCodeInput, TheMobileInput, Form, PageWithHeader },
  props: {
    bindMode: {
      type: Boolean,
      default: false,
    },
  },
  setup (props) {
    const [requestCode] = useResetCode()
    const [reset, progress] = props.bindMode ? useBindMobile() : useReset()

    return {
      registerFormSchema,
      reset,
      progress,
      sendCode (cc: string, mobile: string, cb: () => void) {
        requestCode(cc, mobile, props.bindMode ? 3 : 2).then(cb)
      },
    }
  },
})
